var UserProfile = (function() {
    var userData;
  
    var getUserData = function() {
      // if (!userData) { 
      //   userData = JSON.parse(sessionStorage.getItem("userData"));
      // }
      userData = JSON.parse(sessionStorage.getItem("userData"));
      return userData;
    };
  
    var setUserData = function(userData) {
      sessionStorage.setItem("userData", JSON.stringify(userData));
      userData = JSON.parse(sessionStorage.getItem("userData"));
    };
  
    return {
      getUserData: getUserData,
      setUserData: setUserData
    }
  })();
  
export default UserProfile;